import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useParams, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import emailjs from '@emailjs/browser';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { SectionHeading } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { useAuth } from '../helpers/useAuth';
import { UserHeader } from 'components/headers/UserHeader';
import { Input } from 'components/misc/InputWithValidation';

import API from '../api/axios';
import ClinicSelect from 'components/misc/ClinicSelect';

const Container = tw.div`relative bg-primary-purple text-primary-blue`;
const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;

const SuccessMessage = tw.p`mt-16 text-lg md:text-base lg:text-xl leading-relaxed text-primary-blue font-body`;

const NavLink = tw(
  Link
)`block underline text-linkText hover:text-linkActive visited:text-linkVisited`;
const TextContent = tw.div`lg:py-8 text-center`;

const Heading = tw(
  SectionHeading
)`my-4 text-primary-blue text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;

const FormContainer = tw.div`text-sm flex flex-col max-sm:max-w-sm max-w-lg mx-auto`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 rounded-lg`;

export default ({
  headingText = 'Request Records',
  submitButtonText = 'Send',
  successMessageText = 'Your request has been received!',
}) => {
  const { petId } = useParams();
  const { userId, authToken } = useAuth();
  const methods = useForm();
  const [showSuccess, setShowSuccess] = useState(false);
  const [clinics, setClinics] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    API.get(`/users/${userId}/pets/${petId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => setClinics(res.data.clinics))
      .catch((err) => console.log(err));
  }, []);

  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const requestRecords = methods.handleSubmit((data) => {
    const { clinicId } = data;

    if (!clinicId) {
      methods.setError('clinicId', {
        message: 'Please select a clinic',
      });
      return;
    }

    const requestData = {
      clinicId: clinicId,
      petId,
    };

    API.post('/recordRequests', requestData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        navigate(`/medicalRecords/${petId}`, {
          state: { message: successMessageText },
        });
      })
      .catch((error) => {
        console.log('Error submitting record request:', error);
        console.error('Error submitting record request:', error);
        // Handle the error, e.g., show an error message to the user
        methods.setError('submit', {
          type: 'manual',
          message: 'Failed to submit record request. Please try again.',
        });
      });
  });

  return (
    <UserHeader>
      <Container>
        <TextColumn>
          <TextContent>
            <Heading>{headingText}</Heading>
            {showSuccess ? (
              <SuccessMessage>{successMessageText}</SuccessMessage>
            ) : (
              <FormContainer>
                <FormProvider {...methods} noValidate autoComplete='off'>
                  <form onSubmit={requestRecords}>
                    <ClinicSelect
                      clinics={clinics}
                      placeholder={'Select a Clinic'}
                    />
                    <NavLink to={`/editPet/${petId}`}>
                      Don't see your clinic here? Click here to update your pet
                    </NavLink>
                    <SubmitButton type='submit'>
                      {submitButtonText}
                    </SubmitButton>
                  </form>
                </FormProvider>
              </FormContainer>
            )}
          </TextContent>
        </TextColumn>
      </Container>
    </UserHeader>
  );
};
