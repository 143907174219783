import React, { useEffect, useState, useRef } from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import styled from 'styled-components';

import { UserHeader } from 'components/headers/UserHeader';

import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';

import API from '../api/axios';
import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg';
import exams_icon from '../assets/icons-dark/exams_icon.svg';
import labwork_icon from '../assets/icons-dark/labwork_icon.svg';
import imaging_icon from '../assets/icons-dark/imaging_icon.svg';
import procedure_icon from '../assets/icons-dark/procedure_icon.svg';
import vaccines_icon from '../assets/icons-dark/vaccines_icon.svg';
import prescriptions_icon from '../assets/icons-dark/prescriptions_icon.svg';
import other_icon from '../assets/icons-dark/other_icon.svg';
import edit_icon from '../assets/edit.svg';

import { useAuth } from '../helpers/useAuth';

const Container = tw.div`flex flex-col w-full flex-1 h-screen px-4`;
const TableContainer = tw.div`m-0 w-full sm:mx-4 sm:my-4 text-primary-blue shadow sm:rounded-lg flex justify-center`;

const RecordContainer = tw.div`flex md:flex-row max-sm:flex-col text-primary-blue`;
const Filters = tw.ul`cursor-pointer font-body bg-secondary-teal text-primary-blue my-4 h-fit md:text-lg lg:text-2xl text-center`;
const Filter = tw.li`md:px-2 md:py-1 text-nowrap`;

const StyledImage = styled.img`
  width: 40px;
  margin-right: 4px;

  @media (max-width: 640px) {
    width: 25px;
    margin-right: 2px;
  }
`;

const PrimaryButton = styled.button`
  ${tw`mt-5 tracking-wide font-body lg:text-lg font-semibold bg-primary-blue text-center text-secondary-blue lg:w-56 lg:py-4 px-8 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`lg:w-6 lg:h-6 -ml-2 max-sm:hidden`}
  }
  .text {
    ${tw`lg:ml-3`}
  }
`;

const SecondaryButton = tw(
  PrimaryButtonBase
)`mt-5 inline-block lg:w-64 font-body lg:text-xl tracking-wide text-center lg:py-4`;

const ButtonContainer = tw.div`flex flex-row justify-between`;

const DataTableStyled = styled(DataTable)`
  background-color: white;

  .p-datatable-wrapper {
    @media (min-width: 1024px) {
      min-width: 50rem;
    }
    @media (min-width: 1280px) {
      min-width: 60rem;
    }
  }

  .p-datatable-thead {
    border-bottom: navy 2px solid;
    background-color: white;
    ${tw`md:text-xl max-sm:text-sm`};
  }

  .p-datatable-tbody {
    ${tw`font-body md:text-lg max-sm:text-sm`}
  }

  .p-selectable-row {
    ${tw`border-y py-4`}
  }

  .p-datatable-emptymessage {
    ${tw`text-center`}
  }
`;

const SuccessMessage = tw.p`mb-12 py-6 text-center text-lg md:text-base lg:text-xl leading-relaxed bg-secondary-blue text-primary-blue font-body `;

const file_images = {
  labwork: labwork_icon,
  exam: exams_icon,
  imaging: imaging_icon,
  prescriptions: prescriptions_icon,
  vaccines: vaccines_icon,
  procedure: procedure_icon,
  other: other_icon,
};

const fileTypeFilters = [
  { name: 'Medical Records', value: 'all' },
  { name: 'Exams', value: 'exam' },
  { name: 'Labwork', value: 'labwork' },
  { name: 'Imaging', value: 'imaging' },
  { name: 'Prescriptions', value: 'prescriptions' },
  { name: 'Vaccines', value: 'vaccines' },
  { name: 'Procedures', value: 'procedure' },
  { name: 'Other', value: 'other' },
];

export default ({
  emptyMessage = 'No records have been uploaded yet.',
  PrimaryButtonIcon = PlusIcon,
}) => {
  const location = useLocation();

  const tooltipRef = useRef(null);
  const { petId } = useParams();
  const { userId, authToken } = useAuth();
  const navigate = useNavigate();

  const [selectedFilter, setSelectedFilter] = useState('all');
  const [allFiles, setAllFiles] = useState();
  const [filteredFiles, setFilteredFiles] = useState();

  useEffect(() => {
    API.get(`/users/${userId}/pets/${petId}/medicalRecords`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        setAllFiles(response.data.medicalRecords);
        setFilteredFiles(response.data.medicalRecords);
      })
      .catch((err) => {
        console.log(err);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    tooltipRef.current && tooltipRef.current.updateTargetEvents();
  }, [filteredFiles]);

  const onFileClick = (link) => {
    window.open(link, '_blank');
  };

  const updateFilter = (value) => {
    setSelectedFilter(value);
    if (value === 'all') {
      setFilteredFiles(allFiles);
    } else {
      const updated = allFiles.filter((f) => f.type.includes(value));
      setFilteredFiles(updated);
    }
  };

  const documentImageTemplate = (rowData) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {rowData.type.map((file, i) => (
          <StyledImage
            alt={file}
            key={file + '-' + i}
            src={file_images[file]}
            className='doc-img'
            data-pr-tooltip={file}
          />
        ))}
      </div>
    );
  };

  const editIcon = (rowData) => {
    const fileId = rowData.id;
    return (
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => navigate(`/medicalRecords/${petId}/editFile/${fileId}`)}
      >
        <StyledImage
          alt={fileId}
          key={fileId}
          src={edit_icon}
          className='edit-img'
          data-pr-tooltip='edit'
        />
      </div>
    );
  };

  const facilityName = (rowData) => {
    const facilityDisplay =
      rowData.clinics.length === 0 ? rowData.facility : rowData.clinics[0].name; // Check if clinics array is empty
    return (
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => onFileClick(rowData.documentLink)}
      >
        {facilityDisplay}
      </div>
    );
  };

  return (
    <UserHeader>
      <Container>
        {location?.state?.message ? (
          <SuccessMessage>{location?.state?.message}</SuccessMessage>
        ) : (
          <></>
        )}
        <ButtonContainer>
          <PrimaryButton
            as={Link}
            to={`/medicalRecords/${petId}/addFile`}
            css={tw`rounded-full`}
          >
            <PrimaryButtonIcon className='icon' />
            <span className='text'>Add New File</span>
          </PrimaryButton>
          <SecondaryButton
            as={Link}
            to={`/medicalRecords/${petId}/requestRecords`}
            css={tw`rounded-full`}
          >
            <span className='text'>Request Records</span>
          </SecondaryButton>
        </ButtonContainer>
        <RecordContainer>
          <Filters>
            {fileTypeFilters.map((filter, i) => (
              <Filter
                key={i}
                onClick={() => updateFilter(filter.value)}
                style={{
                  backgroundColor: selectedFilter === filter.value && '#0E1152',
                  color: selectedFilter === filter.value && 'white',
                }}
              >
                {filter.name}
              </Filter>
            ))}
          </Filters>
          <TableContainer>
            <Tooltip
              ref={tooltipRef}
              autoHide={false}
              target='.doc-img'
              mouseTrack
              mouseTrackLeft={10}
            />
            <DataTableStyled
              value={filteredFiles}
              stripedRows
              emptyMessage={emptyMessage}
              // selectionMode='single'
              // selection={selectedProduct}
              // onSelectionChange={(e) => onFileClick(e)}
              scrollable
              scrollHeight='600px'
            >
              <Column
                field='edit'
                filterField='edit'
                dataType='edit'
                header='Edit'
                style={{ width: '5%' }}
                body={editIcon}
              ></Column>
              <Column
                field='dateOfRecord'
                filterField='date'
                dataType='date'
                header='Date'
                sortable
                style={{ width: '20%' }}
              ></Column>
              <Column
                field='facility'
                header='Facility'
                sortable
                style={{ width: '45%' }}
                body={facilityName}
              ></Column>
              <Column
                field='type'
                header='Document Type'
                className='p-dt-tooltip'
                style={{ width: '30%' }}
                body={documentImageTemplate}
              ></Column>
            </DataTableStyled>
          </TableContainer>
        </RecordContainer>
      </Container>
    </UserHeader>
  );
};
