import React from 'react';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';

import { SectionHeading } from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';

const Row = tw.div`flex flex-col lg:flex-row mx-auto px-4 sm:px-4 md:px-8 lg:pl-16 lg:pr-8 xl:pl-32 xl:pr-16 bg-navy-purple bg-cover relative max-md:items-center max-md:flex max-md:flex-col max-md:items-center`; // Added relative positioning to Row
const Column = tw.div`flex-col`;
const TextColumn = tw(
  Column
)`mr-auto max-md:mr-0 lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl lg:py-16 py-2`;
const Heading = tw(
  SectionHeading
)`text-left font-bold text-primary-purple leading-snug xl:text-5xl max-w-[600px]`;
const Description = tw(
  SectionDescription
)`mt-4 text-base lg:text-lg text-primary-purple lg:max-w-lg leading-loose`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block font-display text-xl font-bold tracking-wide text-center px-6 py-2`;
const ImageColumn = tw(
  Column
)`relative mt-12 lg:mt-0 lg:mr-0 flex justify-end items-end`; // Ensure image column aligns to the bottom
const ImageContainer = tw.div`relative z-40 flex justify-center items-end`; // Align image container to bottom
const Image = tw.img`max-w-[300px] w-full relative z-20 object-contain lg:max-w-[700px] xl:max-w-[1000px] xl:pl-32 xl:w-[600px] mb-0`; // Ensure the image touches the bottom by setting mb-0

export default ({ heading, description, primaryButtonText, image }) => {
  return (
    <Row className='bg-navy-teal'>
      <TextColumn>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
        {primaryButtonText && (
          <PrimaryButton as={Link} to='/signup'>
            {primaryButtonText}
          </PrimaryButton>
        )}
      </TextColumn>
      <ImageColumn>
        <ImageContainer>
          <Image src={image} />
        </ImageContainer>
      </ImageColumn>
    </Row>
  );
};
