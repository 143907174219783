import tw from 'twin.macro';
import { Input } from './InputWithValidation';

const ClinicOptionContainer = tw.div`flex flex-col`;
const ClinicOptionRow = tw.div`self-start`;

export default ({
  clinics,
  isMulti = false,
  placeholder,
  defaultValue,
  disabled = false,
}) => {
  const clinicToOption = (c) => {
    return { ...c, value: c.id, label: c.name };
  };

  const valueBuilder = (values) => {
    let current = [];
    if (Array.isArray(values)) {
      current = clinics
        .filter((c) => values.find((v) => v.id === c.id))
        .map(clinicToOption);
    }
    return current;
  };

  const optionComponent = ({ value }) => {
    const clinic = clinics.find((c) => c.id === value);
    return (
      <ClinicOptionContainer>
        <ClinicOptionRow>{clinic.name}</ClinicOptionRow>
        <ClinicOptionRow>
          {clinic.address.city}, {clinic.address.state}
        </ClinicOptionRow>
      </ClinicOptionContainer>
    );
  };

  return (
    <Input
      type='string'
      id='clinics'
      name={isMulti ? 'clinics' : 'clinicId'}
      placeholder={placeholder ?? "Select your pet's Clinics"}
      multiSelectValueBuilder={valueBuilder}
      options={isMulti ? clinics.map(clinicToOption) : undefined}
      dropdownValues={!isMulti ? clinics.map(clinicToOption) : undefined}
      defaultValue={!isMulti ? defaultValue ?? null : defaultValue ?? null}
      disabled={disabled}
      reactSelectProps={{
        styles: {
          multiValue: (baseStyles, state) => ({
            ...baseStyles,
            width: '100%',
            justifyContent: 'space-between',
          }),
        },
        formatOptionLabel: optionComponent,
      }}
    />
  );
};
