import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Input } from 'components/misc/InputWithValidation';
import { usStates } from '../../static/UsStates';
import API from '../../api/axios';
import { clinic_name_validation } from 'utils/clinicInputValidations';
import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg';
import {
  addressLine1_validation,
  city_validation,
  zip_validation,
} from 'utils/userInputValidations';
import tw from 'twin.macro';
import styled from 'styled-components';

const FormContainer = tw.div`text-sm flex flex-col max-sm:max-w-sm max-w-lg mx-auto`;
const SubmitButton = styled.button`
  ${tw`mt-5 font-semibold bg-primary-blue text-secondary-blue w-full py-4 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({ onSubmit = () => {} }) => {
  const methods = useForm();
  const { isSubmitting } = methods.formState;
  const authToken = JSON.parse(localStorage.getItem('authToken'));

  const onFormSubmit = methods.handleSubmit((data) => {
    const { name, email, phone, address } = data;

    API.post(
      `/clinics`,
      {
        name,
        email,
        phone,
        address,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
      .then(async (response) => {
        methods.reset();
        onSubmit(response.data);
      })
      .catch((err) => {
        console.log(err);
        console.error(err);
      });
  });

  return (
    <FormContainer>
      <FormProvider {...methods} autoComplete='off'>
        <form onSubmit={methods.handleSubmit(onFormSubmit)}>
          <Input {...clinic_name_validation} />
          <Input
            {...{
              ...addressLine1_validation,
              name: `address.${addressLine1_validation.name}`,
            }}
          />
          <Input
            type='string'
            id='addressLine2'
            name='address.addressLine2'
            placeholder='Address Line 2'
          />
          <Input
            {...{
              ...city_validation,
              name: `address.${city_validation.name}`,
            }}
          />
          <Input
            type='string'
            id='state'
            name='address.state'
            placeholder='State'
            dropdownValues={usStates}
          />
          <Input
            {...{
              ...zip_validation,
              name: `address.${zip_validation.name}`,
            }}
          />
          <SubmitButton type='submit' disabled={isSubmitting}>
            <PlusIcon className='icon' />
            <span className='text'>Add new Clinic</span>
          </SubmitButton>
        </form>
      </FormProvider>
    </FormContainer>
  );
};
