import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { NavyContainer } from 'components/misc/Layouts.js';
import RetrieverDog from '../assets/golden_dog_top.png';
import RecordIcons from 'components/banners/RecordIcons';
import ContactUsHero from 'components/hero/ContactUsHero';
import TwoColImage from 'components/layout/TwoColImage';

const Row = tw.div`bg-navy-purple`; // Added relative positioning to Row
const RowSecondary = tw.div`bg-secondary-blue text-primary-blue`;
const RowContactForm = tw.div`bg-primary-purple text-primary-blue`;

export default () => {
  return (
    <>
      <NavyContainer>
        <Row>
          <TwoColImage
            heading={"Your pet's medical records, all in one place"}
            description={
              "At Retriever Veterinary Records, we believe that every pet's medical history should be easily accessible and consolidated, regardless of where their care takes them. By offering a secure, comprehensive platform, we empower pet parents and veterinary professionals alike to provide the best possible care with confidence and ease. Make your vet visits and traveling easier."
            }
            primaryButtonText={'Sign Up'}
            image={RetrieverDog}
          />
        </Row>
        <RowSecondary>
          <RecordIcons />
        </RowSecondary>
        <RowContactForm>
          <ContactUsHero />
        </RowContactForm>
      </NavyContainer>
    </>
  );
};
