import React, { useState } from 'react';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { Container as ContainerBase } from 'components/misc/Layouts';
import { InputError } from 'components/misc/FormError';

import { ReactComponent as LoginIcon } from 'feather-icons/dist/icons/log-in.svg';
import API from '../api/axios';
import { useAuth } from '../helpers/useAuth';

const Container = tw(
  ContainerBase
)`font-medium flex justify-center w-screen h-screen`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 text-primary-purple sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-3xl xl:text-3xl font-extrabold text-primary-blue`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg text-primary-blue font-medium bg-secondary-blue border border-primary-purple placeholder-secondary-blue text-sm focus:outline-none focus:border-secondary-blue focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-blue text-secondary-blue w-full py-4 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({
  headingText = 'Log In To Retriever',
  submitButtonText = 'Log In',
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = '#',
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const { login } = useAuth();

  const onSubmit = (e) => {
    e.preventDefault();
    API.post(
      '/auth/login',
      {
        email,
        password,
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
      .then(async (response) => {
        const { data } = response;
        await login({ data, email });
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        console.log('incorrect credentials', err);
        console.error(err);
      });
  };

  return (
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              <Form onSubmit={onSubmit}>
                <Input
                  type='email'
                  placeholder='Email'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <Input
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errorMessage && <InputError message={errorMessage} />}
                <SubmitButton type='submit'>
                  <SubmitButtonIcon className='icon' />
                  <span className='text'>{submitButtonText}</span>
                </SubmitButton>
              </Form>
              {/* <p tw='mt-6 text-xs text-secondary-blue text-center'>
                <a
                  href={forgotPasswordUrl}
                  tw='border-b border-secondary-blue border-dotted'
                >
                  Forgot Password ?
                </a>
              </p> */}
              <p tw='mt-8 text-sm text-primary-blue text-center'>
                Dont have an account?{' '}
                <Link
                  to='/signup'
                  tw='border-b border-secondary-blue border-dotted'
                >
                  Sign Up
                </Link>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  );
};
