import React, { useState, useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import FormData from 'form-data';
import moment from 'moment';

import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { Container as ContainerBase } from 'components/misc/Layouts';
import { Input } from 'components/misc/InputWithValidation';
import {
  file_type_validation,
  visit_date_validation,
  pet_hospital_validation,
} from '../utils/fileUploadValidations';
import { FileUploader } from 'components/misc/FileUploader';
import { SectionHeading } from 'components/misc/Headings.js';

import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg';
import API from '../api/axios';
import { useAuth } from '../helpers/useAuth';
import { InputError } from '../components/misc/FormError';
import ClinicSelect from 'components/misc/ClinicSelect';

const Container = tw(
  ContainerBase
)`font-medium flex justify-center w-screen my-4`;

const FormContainer = tw.div`text-sm flex flex-col max-sm:max-w-sm max-w-lg mx-auto`;
const Heading = tw(
  SectionHeading
)`my-4 text-primary-blue text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;
const TextContent = tw.div`flex flex-col text-center`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-blue text-secondary-blue w-full py-4 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({
  headingText = 'Add New File',
  submitButtonText = 'Add File',
  SubmitButtonIcon = PlusIcon,
}) => {
  const { userId, authToken } = useAuth();
  const methods = useForm();
  const { isSubmitting } = methods.formState;

  const { petId } = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [petFile, setPetFile] = useState();
  const maxSize = 2097152;
  const [clinics, setClinics] = useState([]);

  useEffect(() => {
    API.get(`/users/${userId}/pets/${petId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => setClinics(res.data.clinics))
      .catch((err) => console.log(err));
  }, []);

  const saveFile = useCallback((acceptedFiles) => {
    setPetFile(acceptedFiles);
  }, []);

  const removePetFile = () => {
    setPetFile();
    acceptedFiles.splice(0, acceptedFiles.length);
    fileRejections.splice(0, fileRejections.length);
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        'application/pdf': [],
      },
      maxFiles: 1,
      onDrop: saveFile,
      maxSize,
    });
  const onSubmit = methods.handleSubmit((data) => {
    if (petFile) {
      const { type, clinicId, dateOfRecord } = data;

      if (!Array.isArray(type)) {
        setErrorMessage('File type is required');
        return;
      }
      if (!clinicId) {
        setErrorMessage('Clinic is required');
        return;
      }

      const dateFormatted = moment(dateOfRecord).format('YYYY-MM-DD');

      const formData = new FormData();
      formData.append('file', petFile[0]);
      formData.append(
        'type',
        type.map((t) => t.value)
      );
      formData.append(
        'facility',
        clinics.find((c) => c.id === clinicId)?.name ?? ''
      );
      formData.append('clinics', clinicId);
      formData.append('dateOfRecord', dateFormatted);

      API.post(`/users/${userId}/pets/${petId}/medicalRecords`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      })
        .then(async (response) => {
          setSuccess(true);
          methods.reset();
          navigate(`/medicalRecords/${petId}`);
        })
        .catch((err) => {
          setErrorMessage(err.response.data.message);
          console.log(err);
          console.error(err);
        });
    } else {
      setErrorMessage('Pet File is required');
    }
  });

  return (
    <Container>
      <TextColumn>
        <TextContent>
          <Heading>{headingText}</Heading>
          <FormContainer>
            <FormProvider {...methods} autoComplete='off'>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {errorMessage && <InputError message={errorMessage} />}
                <FileUploader
                  {...{
                    acceptedFiles,
                    fileRejections,
                    getRootProps,
                    getInputProps,
                  }}
                  file={petFile}
                  removeFile={removePetFile}
                  uploadMessage={`Drag & Drop or click to upload your pet's file here! (PDF Only)`}
                />
                <Input {...file_type_validation} control={methods.control} />
                <Input {...visit_date_validation} />
                <ClinicSelect
                  clinics={clinics}
                  placeholder={'Select a Clinic'}
                />
                <SubmitButton type='submit' disabled={isSubmitting}>
                  <SubmitButtonIcon className='icon' />
                  <span className='text'>{submitButtonText}</span>
                </SubmitButton>
              </form>
            </FormProvider>
          </FormContainer>
        </TextContent>
      </TextColumn>
    </Container>
  );
};
