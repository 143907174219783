import { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const ToggleContainer = tw.div`cursor-pointer flex justify-between items-center`;
const Header = tw.span`text-sm font-bold px-2`;
const LabelGroup = tw.div`flex justify-around bg-white rounded-full`;
const Label = styled.span`
  ${tw`text-sm rounded-full bg-white px-2`}

  &.selected {
    ${tw`bg-primary-blue text-white`}
  }
`;

export default ({ header, labels = [], onUpdate = () => {} }) => {
  const [position, setPosition] = useState(0);

  const toggle = () => {
    const newPos = (position + 1) % labels.length;
    setPosition(newPos);
    onUpdate(newPos);
  };

  return (
    <ToggleContainer onClick={toggle}>
      {header ? <Header>{header} </Header> : <></>}

      <LabelGroup>
        {labels.map((label, i) => (
          <Label
            key={`toggle-label-${i}`}
            className={`${i === position ? 'selected' : ''} pos-${i}`}
          >
            {label}
          </Label>
        ))}
      </LabelGroup>
    </ToggleContainer>
  );
};
