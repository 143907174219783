import tw from 'twin.macro';
import Select from 'react-select';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { SectionDescription } from 'components/misc/Typography.js';
import { findInputError, isFormInvalid } from '../../utils/formValidations';

import { InputError } from './FormError';

const InputContainer = styled.div`
  ${tw`relative py-1 mt-2`}
  .disabled {
    ${tw`bg-primary-purple cursor-not-allowed`}
  }
`;
const StyledInput = tw.input`
  font-body w-full px-4 py-4 rounded-lg font-medium bg-secondary-blue border border-primary-purple placeholder-secondary-blue text-base focus:outline-none focus:border-secondary-blue focus:bg-white mt-5 first:mt-0
`;
const Label = tw(SectionDescription)`text-base lg:text-base max-w-lg`;
const StyledSelect = tw.select`font-body w-full px-8 py-4 rounded-lg font-medium bg-secondary-blue border border-primary-purple placeholder-secondary-blue text-base focus:outline-none focus:border-secondary-blue focus:bg-white mt-5 first:mt-0`;
const StyledMultiSelect = styled(Select)`
  .MultiSelect__indicator-separator {
    ${tw`hidden`}
  }
  .MultiSelect__control {
    ${tw`bg-secondary-blue px-8 py-4 border-none`}
  }
  .MultiSelect__placeholder {
    ${tw`text-primary-blue`}
  }
  .MultiSelect__multi-value {
    ${tw`bg-white border border-primary-blue rounded-lg`}
  }
  .MultiSelect__multi-value__label {
    ${tw`text-primary-blue text-base`}
  }
  .MultiSelect__dropdown-indicator {
    ${tw`text-primary-blue text-base`}
  }

  ${tw`font-body w-full rounded-lg font-medium bg-secondary-blue border border-primary-purple placeholder-primary-blue text-base focus:outline-none focus:border-secondary-blue focus:bg-white mt-5 first:mt-0`};
`;

const InputSpacing = tw.div`mt-2`;
const StyledDateWrapper = styled.div`
  .datePicker {
    display: flex;
  }

  .react-datepicker__input-container input {
    ${tw`placeholder-secondary-blue w-full px-8 py-4`}
  }
  ${tw`font-body w-full rounded-lg font-medium bg-secondary-blue border border-primary-purple placeholder-primary-blue text-base focus:outline-none focus:border-secondary-blue focus:bg-white mt-5 first:mt-0`};
`;
const TextArea = tw.textarea`font-body w-full px-4 py-4 rounded-lg font-medium bg-secondary-blue border border-primary-purple placeholder-secondary-blue text-base focus:outline-none focus:border-secondary-blue focus:bg-white mt-5 first:mt-0`;

const defaultValueBuilder = (value, options) => {
  return value
    ? options.filter((o) => value.find((v) => v.value === o.value))
    : [];
};

export const Input = ({
  name,
  type,
  id,
  placeholder,
  validation,
  dropdownValues,
  isTextArea,
  control,
  options,
  multiSelectValueBuilder = defaultValueBuilder,
  reactSelectProps = {},
  defaultValue,
  disabled,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  if (Array.isArray(dropdownValues)) {
    dropdownValues = [
      { value: '', label: 'Choose an option' },
      ...dropdownValues,
    ];
  }

  return (
    <InputContainer>
      <Label htmlFor={id} className='font-semibold capitalize'>
        {placeholder}
      </Label>
      {isInvalid && (
        <InputError
          message={inputErrors.error.message}
          key={inputErrors.error.message}
        />
      )}
      <InputSpacing>
        {dropdownValues ? (
          <StyledSelect
            {...register(name)}
            className={disabled ? 'disabled' : ''}
            disabled={disabled}
          >
            {dropdownValues.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </StyledSelect>
        ) : isTextArea ? (
          <TextArea
            id={id}
            type={type}
            placeholder={placeholder}
            {...register(name, validation)}
          />
        ) : type === 'date' ? (
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, ref } }) => (
              <StyledDateWrapper>
                <DatePicker
                  inputRef={ref}
                  wrapperClassName='datePicker'
                  placeholderText={placeholder}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={30}
                  onChange={(date) => onChange(date)}
                  selected={value}
                  key={name}
                />
              </StyledDateWrapper>
            )}
          />
        ) : options ? (
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <StyledMultiSelect
                  inputRef={ref}
                  classNamePrefix='MultiSelect'
                  value={multiSelectValueBuilder(value, options)}
                  onChange={(val) =>
                    onChange(
                      val.map((c) => options.find((o) => o.value === c.value))
                    )
                  }
                  options={options}
                  placeholder={placeholder ?? 'Does this file contain...'}
                  isMulti
                  className={disabled ? 'disabled' : ''}
                  disabled={disabled}
                  {...reactSelectProps}
                />
              );
            }}
          />
        ) : (
          <StyledInput
            id={id}
            key={id}
            type={type}
            placeholder={placeholder}
            value={defaultValue}
            className={disabled ? 'disabled' : ''}
            {...register(name, validation)}
            disabled={disabled}
          />
        )}
      </InputSpacing>
    </InputContainer>
  );
};
