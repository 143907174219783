import React from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import useAnimatedNavToggler from '../../helpers/useAnimatedNavToggler.js';

import logo from '../../assets/Retriever_Logo_Text.svg';
import { useAuth } from '../../helpers/useAuth.js';
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { useLocalStorage } from 'helpers/useLocalStorage.js';
import ToggleSlider from 'components/misc/ToggleSlider.js';

const Header = styled.header(({ isAdmin }) => [
  tw`
  flex justify-between items-center
  mx-auto
  font-display text-primary-blue px-16 py-2 overflow-hidden opacity-100 w-full h-[100px]
`,
  isAdmin ? tw`bg-secondary-blue` : tw`bg-primary-purple`,
]);

export const NavLinks = tw.div`flex justify-end items-center flex-wrap-reverse font-display`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-purple will apply the bg-primary-purple class on hover or focus
 */
export const NavLink = tw.a`
  text-xl my-2 lg:mx-4 xl:mx-6 lg:my-0 font-light
  tracking-wide transition duration-300 text-primary-blue 
  pb-1 border-b-2 border-transparent hocus:font-medium
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0 px-6 py-2 rounded-lg bg-primary-blue text-primary-purple font-medium
  hocus:bg-primary-purple hocus:text-primary-purple focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-display border-b-0 text-4xl! ml-0!`};

  img {
    ${tw`w-[12rem] mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hover:text-secondary-blue transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-primary-purple bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const AdminChip = tw.div`flex flex-col items-center`;
export const AdminLabel = tw.label`text-primary-blue text-4xl`;

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  className,
  collapseBreakpointClass = 'lg',
}) => {
  const [showAdminLinks, setShowAdminLinks] = useLocalStorage(
    'adminLinks',
    true
  );
  const { authToken, logout, isAdmin } = useAuth();
  const default_links = [
    <NavLinks key='nav_links'>
      <NavLink href='/#' key='home'>
        Home
      </NavLink>
      <NavLink href='/about' key='about'>
        About
      </NavLink>
      <NavLink href='/contact' key='contact'>
        Contact
      </NavLink>
      <NavLink href='/forvets' key='for-vets'>
        For Vets
      </NavLink>
      <NavLink href='/login' tw='lg:ml-12!' key='login'>
        Log in
      </NavLink>
      <PrimaryLink href='/signup' key='signup'>
        Sign up
      </PrimaryLink>
    </NavLinks>,
  ];

  const logged_in_links = [
    <NavLinks key='nav_links'>
      <NavLink href='/mypets' key='my-pets'>
        My Pets
      </NavLink>
      <NavLink href='/contact' key='contact'>
        Contact
      </NavLink>
      <PrimaryLink href='/' key='signout' onClick={logout}>
        Sign Out
      </PrimaryLink>
    </NavLinks>,
  ];

  const admin_links = [
    <NavLinks key='nav_links'>
      <NavLink href='/admin' key='admin-home'>
        Admin Home
      </NavLink>
      <NavLink href='/admin/users' key='admin-users'>
        Users
      </NavLink>
      <NavLink href='/admin/clinics' key='admin-clinics'>
        Clinics
      </NavLink>
      <NavLink href='/admin/recordRequests' key='admin-record-requests'>
        Record Requests
      </NavLink>
      <PrimaryLink href='/' key='signout' onClick={logout}>
        Sign Out
      </PrimaryLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href='/' key='logo'>
      <img src={logo} alt='logo' />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;

  const linkList = authToken
    ? isAdmin() && showAdminLinks
      ? admin_links
      : logged_in_links
    : default_links;

  return (
    <Header {...{ isAdmin }}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {isAdmin() ? (
          <AdminChip>
            <AdminLabel>ADMIN</AdminLabel>
            <ToggleSlider
              header='View:'
              labels={['Admin', 'User']}
              onUpdate={() => setShowAdminLinks(!showAdminLinks)}
            />
          </AdminChip>
        ) : (
          ''
        )}
        {linkList}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        {isAdmin() ? (
          <AdminChip>
            <AdminLabel>ADMIN</AdminLabel>
            <ToggleSlider
              header='View:'
              labels={['Admin', 'User']}
              onUpdate={() => setShowAdminLinks(!showAdminLinks)}
            />
          </AdminChip>
        ) : (
          ''
        )}

        <MobileNavLinks
          initial={{ x: '150%', display: 'none' }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
          key='mobile-nav-links'
        >
          {linkList}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? 'open' : 'closed'}
        >
          {showNavLinks ? (
            <CloseIcon tw='w-6 h-6' key='close-icon' />
          ) : (
            <MenuIcon tw='w-6 h-6' key='menu-icon' />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
