import React from 'react';
import { useNavigate } from 'react-router-dom';

import tw from 'twin.macro';

import { Container as ContainerBase } from 'components/misc/Layouts';
import { SectionHeading } from 'components/misc/Headings.js';
import AddClinicForm from 'components/forms/AddClinicForm';

const Container = tw(
  ContainerBase
)`font-medium flex justify-center -m-8 w-screen my-4`;

const Heading = tw(
  SectionHeading
)`my-4 text-primary-blue text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;

const TextContent = tw.div`flex flex-col text-center`;

export default ({ headingText = 'Add A New Clinic' }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <TextColumn>
        <TextContent>
          <Heading>{headingText}</Heading>
          <AddClinicForm onSubmit={() => navigate(`/admin/clinics`)} />
        </TextContent>
      </TextColumn>
    </Container>
  );
};
