import React, { useEffect, useState, useRef } from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import styled from 'styled-components';

import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';

import API from '../api/axios';
import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg';

import { useAuth } from '../helpers/useAuth';
import { AdminHeader } from 'components/headers/AdminHeader';
import { CircularProgress } from '@mui/material';
import Edit from '../assets/edit.svg';

const Container = tw.div`flex flex-col w-full flex-1 h-full px-4 min-h-[600px]`;
const TableContainer = tw.div`m-0 w-full sm:mx-4 sm:my-4 text-primary-blue shadow sm:rounded-lg flex justify-center`;

const RecordContainer = tw.div`flex md:flex-row max-sm:flex-col text-primary-blue`;

const ActionContainer = tw.div`flex flex-row mt-5 gap-4 justify-end`;
const PrimaryButton = styled.button`
  ${tw`tracking-wide font-body lg:text-lg font-semibold bg-primary-blue text-center text-secondary-blue lg:w-56 lg:py-4 px-8 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`lg:w-6 lg:h-6 -ml-2 max-sm:hidden`}
  }
  .text {
    ${tw`lg:ml-3`}
  }
`;
const PrimaryLink = styled.a`
  ${tw`tracking-wide font-body lg:text-lg font-semibold bg-primary-blue text-center text-secondary-blue lg:w-56 lg:py-4 px-8 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`lg:w-6 lg:h-6 -ml-2 max-sm:hidden`}
  }
  .text {
    ${tw`lg:ml-3`}
  }
`;
const ImageContainer = tw.a`flex-shrink-0 self-center`;
const Image = tw.img`max-w-full w-8 md:w-12 relative mr-4 md:mr-12`;

const Input = tw.input`w-64 px-8 py-4 rounded-lg font-medium bg-white border border-secondary-blue placeholder-secondary-blue focus:outline-none focus:border-secondary-blue focus:bg-white mt-5 first:mt-0`;

const LinkContainer = tw.div`flex flex-row gap-4 underline`;

const DataTableStyled = styled(DataTable)`
  background-color: white;

  .p-datatable-wrapper {
    @media (min-width: 1024px) {
      min-width: 50rem;
    }
    @media (min-width: 1280px) {
      min-width: 60rem;
    }
  }

  .p-datatable-thead {
    border-bottom: navy 2px solid;
    background-color: white;
    ${tw`md:text-xl max-sm:text-sm`};
  }

  .p-datatable-tbody {
    ${tw`font-body md:text-lg max-sm:text-sm`}
  }

  .p-selectable-row {
    ${tw`border-y py-4`}
  }

  .p-datatable-emptymessage {
    ${tw`text-center`}
  }
`;

export default ({
  emptyMessage = 'No record requests have been submitted yet.',
  PrimaryButtonIcon = PlusIcon,
}) => {
  const tooltipRef = useRef(null);
  const { userId, authToken } = useAuth();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState('');
  const [searching, setSearching] = useState(true);
  const [recordRequests, setRecordRequests] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState();

  useEffect(() => {
    API.get(`/recordRequests`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setRecordRequests(res.data.recordRequests);
      })
      .catch((err) => {
        console.log(err);
        console.error(err);
      })
      .finally(() => setSearching(false));
  }, []);

  useEffect(() => {
    tooltipRef.current && tooltipRef.current.updateTargetEvents();
  }, [filteredFiles]);

  const searchByName = () => {
    if (searchInput === '') {
      return;
    }

    setSearching(true);

    // TODO: Endpoint not implemented yet.
    API.get(`/recordRequests/search?name=${encodeURIComponent(searchInput)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        setRecordRequests(response.data);
      })
      .catch((err) => {
        console.log(err);
        console.error(err);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const rowValue = (key) => {
    return (rowData) => {
      const keys = key.split('.');
      let value = rowData;
      for (let k of keys) {
        if (value && value.hasOwnProperty(k)) {
          value = value[k];
        } else {
          value = undefined;
          break;
        }
      }
      if (value === undefined || value === null) {
        return <i tw='text-secondary-blue'>{`No ${key} provided`}</i>;
      }

      return value;
    };
  };

  const edit = (rowData) => {
    return (
      <ImageContainer href={`/admin/recordRequests/${rowData.id}`}>
        <Image src={Edit} />
      </ImageContainer>
    );
  };

  return (
    <AdminHeader page='record requests'>
      <Container>
        <ActionContainer>
          {/* <Input
            placeholder='Search by Owner Email'
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            onSubmit={searchByName}
          />
          <PrimaryButton onClick={searchByName} css={tw`rounded-full`}>
            <span className='text'>Search Clinic</span>
          </PrimaryButton> */}
          {/* <PrimaryLink href='/admin/clinics/new' css={tw`rounded-full`}>
            Create Clinic
          </PrimaryLink> */}
        </ActionContainer>
        <RecordContainer>
          {searching ? (
            <>
              <CircularProgress></CircularProgress>
              Searching...
            </>
          ) : (
            <TableContainer>
              <Tooltip
                ref={tooltipRef}
                autoHide={false}
                target='.doc-img'
                mouseTrack
                mouseTrackLeft={10}
              />
              <DataTableStyled
                value={recordRequests}
                stripedRows
                emptyMessage={emptyMessage}
                scrollable
                scrollHeight='600px'
              >
                <Column style={{ width: '5%' }} body={edit}></Column>
                <Column
                  field='id'
                  header='ID'
                  sortable
                  body={rowValue('id')}
                ></Column>
                <Column
                  field='status'
                  header='Status'
                  body={rowValue('status')}
                ></Column>
                <Column
                  field='pet_name'
                  header='Pet Name'
                  body={rowValue('pet.name')}
                ></Column>
                <Column
                  field='clinic_name'
                  header='Clinic Name'
                  body={rowValue('clinic.name')}
                ></Column>
              </DataTableStyled>
            </TableContainer>
          )}
        </RecordContainer>
      </Container>
    </AdminHeader>
  );
};
